const ppayRouter = {
  list: {
    path: '/ppay_list',
    name: 'PpayList',
    component: () => import('@/views/FinanceManagement/PpayManage/PpayList.vue'),
    meta: {
      title: '采购付款单 列表'
    }
  },
  edit: {
    path: '/ppay_edit',
    name: 'PpayEdit',
    component: () => import('@/views/FinanceManagement/PpayManage/PpayEdit.vue'),
    meta: {
      title: '采购付款单 编辑'
    }
  }
};
export default ppayRouter;
