const roleRouter = {
  list: {
    path: '/role_list',
    name: 'RoleList',
    component: () => import('@/views/SystemManagement/RoleManage/RoleList.vue'),
    meta: {
      title: '角色信息列表'
    }
  },
  edit: {
    path: '/role_edit',
    name: 'RoleEdit',
    component: () => import('@/views/SystemManagement/RoleManage/RoleEdit.vue'),
    meta: {
      title: '编辑角色信息'
    }
  }
};
export default roleRouter;
