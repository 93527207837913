const exorRouter = {
  list: {
    path: '/exor_list',
    name: 'ExorList',
    component: () => import('@/views/SalesManagement/ExorManage/ExorList.vue'),
    meta: {
      title: '销售合同明细 列表'
    }
  }
};
export default exorRouter;
