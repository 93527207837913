const contractRouter = {
  list: {
    path: '/contract_list',
    name: 'ContractList',
    component: () => import('@/views/CollectionManagement/ContractManage/ContractList.vue'),
    meta: {
      title: '托收合同 列表'
    }
  },
  edit: {
    path: '/contract_edit',
    name: 'ContractEdit',
    component: () => import('@/views/CollectionManagement/ContractManage/ContractEdit.vue'),
    meta: {
      title: '托收合同 编辑'
    }
  }
};
export default contractRouter;
