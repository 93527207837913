const podcRouter = {
  list: {
    path: '/podc_list',
    name: 'PodcList',
    component: () => import('@/views/SalesManagement/PodcManage/PodcList.vue'),
    meta: {
      title: '成品采购变更单 列表'
    }
  },
  add: {
    path: '/podc_add',
    name: 'PodcAdd',
    component: () => import('@/views/SalesManagement/PodcManage/PodcAdd.vue'),
    meta: {
      title: '成品采购变更单 新增'
    }
  },
  edit: {
    path: '/podc_edit',
    name: 'PodcEdit',
    component: () => import('@/views/SalesManagement/PodcManage/PodcEdit.vue'),
    meta: {
      title: '成品采购变更单 编辑'
    }
  }
};
export default podcRouter;
