const segiRouter = {
  list: {
    path: '/segi_list',
    name: 'SegiList',
    component: () => import('@/views/FinanceManagement/SegiManage/SegiList.vue'),
    meta: {
      title: '收款登记单 列表'
    }
  },
  add: {
    path: '/segi_add',
    name: 'SegiAdd',
    component: () => import('@/views/FinanceManagement/SegiManage/SegiAdd.vue'),
    meta: {
      title: '收款登记单 新增'
    }
  },
  edit: {
    path: '/segi_edit',
    name: 'SegiEdit',
    component: () => import('@/views/FinanceManagement/SegiManage/SegiEdit.vue'),
    meta: {
      title: '收款登记单 编辑'
    }
  }
};
export default segiRouter;
