const inspectionRouter = {
  list: {
    path: '/inspectionprod_list',
    name: 'InspectionList',
    component: () => import('@/views/InspectionManagement/InspectionManage/InspectionList.vue'),
    meta: {
      title: '成品验货申请清单 列表'
    }
  }
};
export default inspectionRouter;
