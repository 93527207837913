// import VueCookies from 'vue-cookies';
// import Vue from 'vue';
// Vue.use(VueCookies)
// import VueCookies from 'vue-cookies'; import Vue from 'vue'; Vue.use(VueCookies)
import axios from 'axios';
import { Number } from 'core-js';
import { downloadFile } from '@api/public';

export default {
  //将json或Object转化成FormData
  toFormData(param) {
    let formData = new FormData();
    Object.keys(param).forEach(key => {
      formData.append(key, param[key]);
    });
    return formData;
  },
  //过滤后只保留英语字母和数字
  keepEngNum(str) {
    return str.replace(/[^0-9a-zA-Z]/g, '');
  },
  //过滤后只保留英语字母和数字和_
  keepEngNumG(str) {
    return str.replace(/[^0-9a-zA-Z_]/g, '');
  },
  //过滤后只保留英语字母和数字-
  keepEngNum3(str) {
    return str.replace(/[^\u4E00-\u9FA50-9a-zA-Z-]/g, '');
  },
  //只输入数字
  pureNumber(str) {
    return str.replace(/[^0-9Xx]/g, '');
  },
  //过滤后只保留英语字母和数字和小数点加-加_@
  keepEngNum2(str) {
    return str.replace(/[^0-9a-zA-Z-_@.\s]/g, '');
  },
  //只能输入英文
  pureE(str) {
    return str.replace(/[^A-Za-z]/g, '');
  },
  pureEC(str) {
    return str.replace(/[^A-Za-z-]/g, '');
  },
  // 不能输入汉语
  NotkeepChin(str) {
    return str.replace(/[\u4E00-\u9FA5]/g, '');
  },
  // 只能输入汉字
  pureC(str) {
    return str.replace(/[^\u4E00-\u9FA5]/g, '');
  },
  // 只能输入电话
  purephone(str) {
    return str.replace(/[^0-9-]/g, '');
  },
  // 过滤后只保留数字和.
  keepTNum1(number) {
    return number.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.');
  },
  // 过滤后只保留数字和.
  keepTNum2(number) {
    return number.replace(/[^0-9.-]/g, '');
  },
  // 只能输入汉语
  keepChin(str) {
    return str.replace(/[^\u4e00-\u9fa5]/g, '');
  },
  // 限定输入正整数数字 ---用于公积金账号
  keepTNum(number) {
    return number.replace(/\D/g, '');
  },
  // 不可空格
  keepSpace2(number) {
    if (/\s/g.test(number)) {
      return number.substr(0, number.indexOf(' '));
    } else {
      return number;
    }
  },
  // 限定不能输入空格
  keepSpace1(number) {
    if (/\s/g.test(number)) {
      return number.substr(0, number.indexOf(' '));
    } else {
      this.keepEngNumG(number);
    }
  },
  // 验证身份证信息
  statusId(number) {
    let ze = /[^0-9Xx]/g;
    if (ze.test(number)) {
      return number;
    } else {
      return '';
    }
  },
  tranNoRegel(str) {
    if (str.length < 5) {
      return str.replace(/\D/g, '');
    } else {
      let ze = /[A-Z]/g;
      let str1 = str.substr(0, 4);
      let str2 = str.substr(4, 5);
      if (ze.test(str2)) {
        return str;
      } else {
        return str1;
      }
    }
  },
  //时间戳转换成显示时间(到秒)
  toTime(time) {
    var date = new Date(parseInt(time) * 1000);
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
  },
  //时间戳转换成显示时间(到秒)13位
  toTime13(time) {
    var date = new Date(parseInt(time));
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
  },
  //Date转换成显示时间(到秒)
  getDateTime() {
    let date = new Date();
    let Y = date.getFullYear() + '年';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + '时';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + '分';
    let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()) + '秒';
    return '[' + Y + M + D + h + m + s + ']';
  },
  //时间戳转换成显示时间(到日)
  toTimeDay(time) {
    var date = new Date(parseInt(time) * 1000);
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';

    return Y + M + D;
  },
  //时间戳转换成显示时间(到年)
  toTimeYear(time) {
    var date = new Date(parseInt(time) * 1000);
    let Y = date.getFullYear();
    return Y;
  },
  // 国标时间转成时间戳 10 位
  toTimeVal(localTime) {
    return (localTime = Math.round(new Date(localTime).getTime() / 1000));
  },

  // 获取时间开始时间和结束时间戳
  getTime(timeVal) {
    if (timeVal && timeVal.length === 2) {
      timeVal.startTime = timeVal[0];
      timeVal.endTime = timeVal[1];
      timeVal.startTime = Number(new Date(timeVal.startTime).getTime() / 1000);
      timeVal.endTime = Number(new Date(timeVal.endTime).getTime() / 1000);
      return timeVal;
    } else {
      timeVal = [];
      return timeVal;
    }
  },
  // 离职司龄
  // toLeaveSen(entryDate,leaveDate){
  //   entryDate = new Date(entryDate*1000)
  //   leaveDate = new Date(leaveDate*1000)
  //   if(leaveDate > entryDate){
  //     let year = leaveDate.getFullYear() - entryDate.getFullYear();
  //     let month = leaveDate.getMonth() - entryDate.getMonth();
  //     if (month < 0) {
  //       year--;
  //       month = leaveDate.getMonth() + (12 - entryDate.getMonth());
  //     }
  //     if(year === 0 && month ===0){
  //       return entryDate = '未满一个月'
  //     } else if(year ===0 && month > 0){
  //       return entryDate = month + '个月'
  //     }
  //     return entryDate = year+ '年 ' + month + '个月'
  //   } else {
  //     return entryDate = "离职日期小于现在时间!"
  //   }
  // },

  // 计算司龄
  toSeniority(entryDate, leaveDate) {
    // if(leaveDate !==0){
    //  return entryDate = this.toLeaveSen(entryDate,leaveDate)
    // }
    let nowDate = new Date().getTime();
    entryDate = new Date(entryDate * 1000).getTime();
    if (leaveDate !== 0) {
      nowDate = new Date(leaveDate * 1000).getTime();
    }
    if (nowDate > entryDate) {
      let workDate = (nowDate - entryDate) / (60 * 60 * 24 * 30 * 1000);
      if (workDate >= 12) {
        // 一年以上
        if (workDate % 12 === 0) {
          return parseInt(workDate / 12) + '年';
        } else {
          return parseInt(workDate / 12) + '年' + parseInt(workDate % 12) + '个月';
        }
      } else if (parseInt(workDate) > 0) {
        return parseInt(workDate) + '个月';
      } else {
        return '未满一个月';
      }
    } else {
      return '日期填写不正确!';
    }
  },

  // 根据身份证号计算出生日期，年龄，性别
  mathUserInfo(stff_identity, num, birth, gender, age) {
    if (stff_identity.length === 18) {
      if (num === 1) {
        return stff_identity.substring(6, 10) + '-' + stff_identity.substring(10, 12) + '-' + stff_identity.substring(12, 14);
      }
      if (num === 2) {
        if (parseInt(stff_identity.substr(-2, 1)) % 2 === 1) {
          return '男';
        } else {
          return '女';
        }
      }
      if (num === 3) {
        var myDate = new Date();
        var month = myDate.getMonth() + 1;
        var day = myDate.getDate();
        age = myDate.getFullYear() - stff_identity.substring(6, 10) - 1;
        if (stff_identity.substring(10, 12) < month || (stff_identity.substring(10, 12) === month && stff_identity.substring(12, 14) <= day)) {
          age++;
        }
        return age;
      }
    } else {
      return null;
    }
  },

  // 获取单个日期
  getNewDate(timeVal) {
    if (timeVal) {
      timeVal = Number(new Date(timeVal).getTime() / 1000);
      return timeVal;
    } else {
      timeVal = null;
      return timeVal;
    }
  },
  // 时间转换为显示时间终极版--带分秒的
  toStringDate(rowVal) {
    if (rowVal) {
      return this.toTime(rowVal);
    }
  },
  // 时间转换为显示时间终极版--整天的
  toStringDay(rowVal) {
    if (rowVal) {
      return this.toTimeDay(rowVal);
    }
  },
  // 数字字符串转成数组
  toArray(strArr) {
    strArr = strArr.split(',').map(Number);
    return strArr;
  },
  // 转换合同状态
  getStatusName(status) {
    if (status === 0) {
      return { name: '草拟', type: 'info' };
    } else if (status === 1) {
      return { name: '在批', type: 'warning' };
    } else {
      return { name: '生效', type: 'success' };
    }
  },
  //转换操作状态
  getInstAction(appr_inst_action) {
    if (appr_inst_action === 1) {
      return { name: '提交', type: 'primary' };
    } else if (appr_inst_action === 2) {
      return { name: '通过', type: 'success' };
    } else {
      return { name: '拒绝', type: 'danger' };
    }
  },
  //转换下一节点状态
  getInstNextNode(appr_inst_next_node) {
    if (appr_inst_next_node === -1) {
      return { name: '草拟', type: 'info' };
    } else if (appr_inst_next_node === 0) {
      return { name: '生效', type: 'success' };
    } else {
      return { name: '在批', type: 'warning' };
    }
  },
  // 拼接图片url地址
  picUrl(url, type) {
    if (!url) return '';
    if (url.indexOf('https') !== -1) return url;
    let header = '/file';
    if (process.env.NODE_ENV === 'production') header = '/erpfile';
    switch (type) {
      case 'l':
        return `${header}${url}.jpg`;
      case 'm':
        return `${header}${url}_m.jpg`;
      case 's':
        return `${header}${url}_s.jpg`;
      default:
        return null;
    }
  },
  // 拼接图片url地址
  picDYJUrl(url, type) {
    if (!url) return '';
    let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
    let header = '';
    switch (process.env.VUE_APP_ENV) {
      case 'production':
        header = 'https://www.fanterp.com/dyjfile';
        break;
      case 'development':
        header = 'http://192.168.1.236/dyjfile';
        break;
      case 'test':
        header = 'https://www.petsprod.com/dyjfile';
        break;
    }
    if (type === 'l') {
      return (url = header + url + '.jpg?' + updateTime);
    } else if (type === 'm') {
      return (url = header + url + '_m.jpg?' + updateTime);
    } else if (type === 's') {
      return (url = header + url + '_s.jpg?' + updateTime);
    }
  },
  // 拼接图片url地址
  picUrlCopy(url, type, updateTime) {
    // let updateTime = JSON.parse(JSON.stringify(new Date().getTime()))
    let header = '/file';
    if (process.env.NODE_ENV === 'production') {
      header = '/erpfile';
    }
    if (type === 'l') {
      return (url = header + url + '.jpg?' + updateTime);
    } else if (type === 'm') {
      return (url = header + url + '_m.jpg?' + updateTime);
    } else if (type === 's') {
      return (url = header + url + '_s.jpg?' + updateTime);
    }
  },
  picUrl2(url, type, updateTime) {
    // let updateTime = new Date().getTime()
    let header = '/file';
    if (process.env.NODE_ENV === 'production') {
      header = '/erpfile';
    }
    if (type === 'l') {
      return (url = header + url + '.jpg?' + updateTime);
    } else if (type === 'm') {
      return (url = header + url + '_m.jpg?' + updateTime);
    } else if (type === 's') {
      return (url = header + url + '_s.jpg?' + updateTime);
    }
  },

  // 组合路径
  megPath(url) {
    if (url.indexOf('https') !== -1) return url;
    let header = '';
    // let header = '/file';
    // if (process.env.NODE_ENV === 'production') {
    //   header = '/erpfile';
    // }
    if (url) {
      return (url = header + url);
    } else {
      return (url = header);
    }
  },
  // 下载文件zip
  downloadZip(url) {
    return new Promise((resolve, reject) => {
      let newUrl;
      let isHttps = url.indexOf('https') !== -1;
      switch (process.env.VUE_APP_ENV) {
        case 'production':
          newUrl = isHttps ? url : 'https://www.fanterp.com/' + url;
          break;
        case 'development':
          newUrl = isHttps ? url : url;
          break;
        case 'test':
          newUrl = isHttps ? url : 'https://www.petsprod.com/' + url;
          break;
      }
      axios
        .get(newUrl, { responseType: 'arraybuffer' })
        .then(data => {
          resolve(data.data);
        })
        .catch(error => {
          reject(error.toString());
        });
    });
  },
  // 成品信息导入图片
  prodDetailImport(val) {
    let imgUrl;
    switch (process.env.VUE_APP_ENV) {
      case 'production':
        imgUrl = 'https://www.fanterp.com/erpfile' + val + '_m.jpg';
        break;
      case 'development':
        imgUrl = '/file' + val + '_m.jpg';
        break;
      case 'test':
        imgUrl = 'https://www.petsprod.com/erpfile' + val + '_m.jpg';
        break;
    }
    return imgUrl;
  },
  // 下载文件
  downloadItem(url, label) {
    let totalUrl;
    switch (process.env.VUE_APP_ENV) {
      case 'production':
        totalUrl = 'https://www.fanterp.com/erpfile' + url + '.pdf';
        break;
      case 'development':
        totalUrl = '/file' + url + '.pdf';
        break;
      case 'test':
        totalUrl = 'https://www.petsprod.com/erpfile' + url + '.pdf';
        break;
    }
    let DownloadLink = document.createElement('a');
    DownloadLink.style = 'display: none'; // 创建一个隐藏的a标签
    DownloadLink.download = label;
    DownloadLink.href = totalUrl;
    document.body.appendChild(DownloadLink);
    DownloadLink.click(); // 触发a标签的click事件
    document.body.removeChild(DownloadLink);
  },
  // 下载文件Excel
  downloadItemExcel(url, label) {
    let totalUrl;
    switch (process.env.VUE_APP_ENV) {
      case 'production':
        totalUrl = 'https://www.fanterp.com/erpfile' + url + '.xls';
        break;
      case 'development':
        totalUrl = '/file' + url + '.xls';
        break;
      case 'test':
        totalUrl = 'https://www.petsprod.com/erpfile' + url + '.xls';
        break;
    }
    let DownloadLink = document.createElement('a');
    DownloadLink.style = 'display: none'; // 创建一个隐藏的a标签
    DownloadLink.download = label;
    DownloadLink.href = totalUrl;
    document.body.appendChild(DownloadLink);
    DownloadLink.click(); // 触发a标签的click事件
    document.body.removeChild(DownloadLink);
  },
  // 下载文件1
  downloadItemC(url, label) {
    // let DownloadLink = document.createElement('a');
    // DownloadLink.style = 'display: none'; // 创建一个隐藏的a标签
    // DownloadLink.download = label;
    // DownloadLink.href = url;
    // document.body.appendChild(DownloadLink);
    // DownloadLink.click(); // 触发a标签的click事件

    // document.body.removeChild(DownloadLink);
    // const blob = new Blob([]);
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(blob);
    // link.download = label;
    // link.click();
    // URL.revokeObjectURL(link.href);
    // axios.get(url, { responseType: "blob" })
    //     .then(response => {
    //
    //     })
    //     .catch(console.error);
    downloadFile({ fileUrl: url, fileName: label });
    // console.log(url, label);
    // let element = document.createElement('a');
    // element.setAttribute('href', url);
    // element.setAttribute('download', label);
    //
    // element.style.display = 'none';
    // document.body.appendChild(element);
    //
    // element.click();
    //
    // document.body.removeChild(element);
  },
  // 聘用类型
  reHireTap(type) {
    if (type === 1) {
      return '返聘';
    } else if (type === 2) {
      return '实习';
    } else if (type == 3) {
      return '劳务';
    } else if (type === 4) {
      return '正式';
    } else if (type == 5) {
      return '试用';
    } else if (type === 6) {
      return '兼职';
    } else {
      return '无生效合同';
    }
  },
  // 时间戳过来默认为0的状态
  isTimeStamp0(timeVal) {
    if (timeVal === 0 || timeVal === null) {
      return (timeVal = null);
    } else {
      return (timeVal = timeVal * 1000);
    }
  },
  // 控制状态删除
  isDelete(selection) {
    let statusList = selection.map(item => {
      return item.status;
    });
    const allStatus = statusList.reduce(function (sum, number) {
      return sum + number;
    }, 0);
    if (allStatus > 0) {
      return (selection = false);
    } else {
      return (selection = true);
    }
  },
  modePrepend(val) {
    if (process.env.NODE_ENV === 'production') {
      return '/erp' + val;
    } else {
      return val;
    }
  },
  // 限定不能输入空格
  keepSpace(number) {
    if (/\s/g.test(number)) {
      return number.replace(/\s/g, '');
    } else {
      return number;
    }
  },
  // 保留两位小数
  reservedDigits(value) {
    if (value || value === 0) {
      value = value.toString();
      let ind = value.toString().indexOf('.');
      if (ind < 0) {
        return value + '.00';
      } else {
        if (value.length - 1 - ind > 2) {
          return value.slice(0, ind + 3);
        } else if (value.length - 1 - ind < 2) {
          let index = 2 - (value.length - 1 - ind);
          for (let i = 0; i < index; i++) {
            value = value + '0';
          }
          return value;
        } else {
          return value;
        }
      }
    }
  },
  // 计算价格保留四位
  calcPrice(num, posi = 0, str) {
    let num1 = 0;
    let fre = null;
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = (num * fre) / fre;
    if (isNaN(num1)) {
      return 0.0;
    } else {
      if (num1 >= str) {
        return str - 0.0001;
      } else {
        let value = num1;
        value = value.toString();
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            return value + '.0000';
          } else {
            if (value.length - 1 - ind > 4) {
              return value.substr(0, ind + 5);
            } else if (value.length - 1 - ind < 4) {
              let index = 4 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0';
              }
              return value;
            } else {
              return value;
            }
          }
        }
      }
    }
  },
  // 计算价格保留四位
  calcPrice2(num, posi = 0, str) {
    let num1 = 0;
    let fre = null;
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = (num * fre) / fre;
    if (isNaN(num1)) {
      return 0.0;
    } else {
      if (num1 >= str) {
        return str - 0.0001;
      } else {
        let value = num1;
        value = value.toString();
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            if (value.length > 10) {
              return '-9999999.9999';
            }
            return value + '.0000';
          } else {
            if (value.length - 1 - ind > 4) {
              return value.substr(0, ind + 5);
            } else if (value.length - 1 - ind < 4) {
              let index = 4 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0';
              }
              return value;
            } else {
              return value;
            }
          }
        }
      }
    }
  },
  // 计算价格保留2位
  calcPriceT(num, posi = 0, str) {
    let num1 = 0;
    num1 = Number(num);
    if (isNaN(num1)) {
      return '0.00';
    } else {
      if (num1 >= str) {
        return str - 0.01;
      } else {
        let value = num1;
        value = value.toString();
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            return value + '.00';
          } else {
            if (value.length - 1 - ind > 2) {
              return value.substr(0, ind + 3);
            } else if (value.length - 1 - ind < 2) {
              let index = 2 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0';
              }
              return value;
            } else {
              return value;
            }
          }
        }
      }
    }
  },
  // 计算数字保留3位四舍五入
  calcNum3(num, posi = 0, str) {
    let num1 = 0;
    num1 = Number(num);
    if (isNaN(num1)) {
      return '0.000';
    } else {
      if (num1 >= str) {
        return str.toFixed(3); // 将str转换为带有3位小数点的定点
      } else {
        // 将num1转换为带有3位小数的固定点
        return num1.toFixed(3);
      }
    }
  },
  // 计算数字保留2位四舍五入
  calcNum2(num, posi = 0, str) {
    let num1 = 0;
    num1 = Number(num);
    if (isNaN(num1)) {
      return '0.00';
    } else {
      if (num1 >= str) {
        return str.toFixed(2); // 将str转换为带有2位小数点的定点
      } else {
        // 将num1转换为带有2位小数的固定点
        return num1.toFixed(2);
      }
    }
  },
  // 保留小数位数且不能超出位数
  retain1(num, posi = 0, str) {
    let num1 = 0;
    let fre = null;
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = Math.floor(num * fre) / fre;
    if (isNaN(num1)) {
      return 0;
    } else {
      if (num1 >= str) {
        return str - 0.01;
      } else {
        let value = num1;
        value = value.toString();
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            return value + '.00';
          } else {
            if (value.length - 1 - ind > 2) {
              return value.substr(0, ind + 3);
            } else if (value.length - 1 - ind < 2) {
              let index = 2 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0';
              }
              return value;
            } else {
              return value;
            }
          }
        }
      }
    }
  },
  retain2(num, posi = 0, str) {
    let num1 = 0;
    let fre = null;
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = Math.floor(num * fre) / fre;
    if (isNaN(num1)) {
      return 0;
    } else {
      if (num1 >= str) {
        return str - 0.001;
      } else {
        let value = num1;
        value = value.toString();
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            return value + '.000';
          } else {
            if (value.length - 1 - ind > 3) {
              return value.substr(0, ind + 4);
            } else if (value.length - 1 - ind < 3) {
              let index = 3 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0';
              }
              return value;
            } else {
              return value;
            }
          }
        }
      }
    }
  },

  // 计算位数保留四位
  haveFour(value) {
    if (value || value === 0) {
      value = value.toString();
      let ind = value.toString().indexOf('.');
      if (ind < 0) {
        return value + '.0000';
      } else {
        if (value.length - 1 - ind > 4) {
          return value.slice(0, ind + 5);
        } else if (value.length - 1 - ind < 4) {
          let index = 4 - (value.length - 1 - ind);
          for (let i = 0; i < index; i++) {
            value = value + '0';
          }
          return value;
        } else {
          return value;
        }
      }
    }
  },
  AntiShake(time = 5000) {
    let timeout = null;
    let flag = true;
    if (timeout) {
      clearTimeout(timeout); // 规定时间内若定时器存在则清除
    }
    if (flag) {
      timeout = setTimeout(() => {
        flag = true;
        return '';
      }, time);
    } else {
      return '';
    }
    flag = false;
    return '';
  },
  jumpDyj(val, val1, val2, val3) {
    switch (process.env.VUE_APP_ENV) {
      case 'production':
        window.open(`https://www.manenwork.com/login?userName=${val.user_name}&userPass=${val1}&page=${val3}&formId=${val2}&creatime=${val.create_time}`);
        break;
      case 'development':
        window.open(`http://192.168.1.181:8885/login?userName=${val.user_name}&userPass=${val1}&page=${val3}&formId=${val2}&creatime=${val.create_time}`);
        break;
      case 'test':
        window.open(`https://www.petsprod.com/vyj/login?userName=${val.user_name}&userPass=${val1}&page=${val3}&formId=${val2}&creatime=${val.create_time}`);
        break;
    }
    // prodDetailImport
    // 线下
    // window.open(`http://localhost:8885/vyj/login?userName=${val.user_name}&userPass=${val1}&page=${val3}&formId=${val2}&creatime=${val.create_time}`)
    // 测试
    // window.open(`https://www.petsprod.com/vyj/login?userName=${val.user_name}&userPass=${val1}&page=${val3}&formId=${val2}&creatime=${val.create_time}`)
    // 线上
    // window.open(`https://www.manenwork.com/login?userName=${val.user_name}&userPass=${val1}&page=${val3}&formId=${val2}&creatime=${val.create_time}`)
  },
  //出运发票号加杠
  transverseTranNo(val) {
    if (!val) return '';
    //判断是否已经有杠
    if (val.length > 10 && val.charAt(10) !== '-' && val.charAt(11) !== '-') {
      let ypAfter = val.substring(val.substring(0, val.indexOf('XP')).length + 2, val.length);
      let ypAfterP4 = ypAfter.substring(0, 4);
      return val.replace(ypAfterP4, ypAfterP4 + '-');
    } else {
      return val;
    }
    // return val.substring(0, 10) + '-' + val.substring(10, val.length);
  }
};
