const oinvRouter = {
  list: {
    path: '/oinv_list',
    name: 'OinvList',
    component: () => import('@/views/SettleManagement/OinvManage/OinvList.vue'),
    meta: {
      title: '其他采购发票 列表'
    }
  },
  add: {
    path: '/oinv_add',
    name: 'OinvAdd',
    component: () => import('@/views/SettleManagement/OinvManage/OinvAdd.vue'),
    meta: {
      title: '其他采购发票 新增'
    }
  },
  edit: {
    path: '/oinv_edit',
    name: 'OinvEdit',
    component: () => import('@/views/SettleManagement/OinvManage/OinvEdit.vue'),
    meta: {
      title: '其他采购发票 编辑'
    }
  }
};
export default oinvRouter;
