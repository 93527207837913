const requisitionRouter = {
  list: {
    path: '/requisition_list',
    name: 'requisitionList',
    component: () => import('@/views/FinanceManagement/RequisitionManage/RequisitionList.vue'),
    meta: {
      title: '开票通知单 列表'
    }
  },
  edit: {
    path: '/requisition_edit',
    name: 'requisitionEdit',
    component: () => import('@/views/FinanceManagement/RequisitionManage/RequisitionEdit.vue'),
    meta: {
      title: '开票通知单 编辑'
    }
  },
  idfam: {
    path: '/invoiceDetailsForAuxiliaryMaterials',
    name: 'InvoiceDetailsForAuxiliaryMaterials',
    component: () => import('@/views/FinanceManagement/RequisitionManage/InvoiceDetailsForAuxiliaryMaterials.vue'),
    meta: {
      title: '辅料开票明细'
    }
  }
};
export default requisitionRouter;
