const pinvchangeRouter = {
  list: {
    path: '/pinvchange_list',
    name: 'PinvChangeList',
    component: () => import('@/views/FinanceManagement/PinvchangeManage/PinvNewList.vue'),
    meta: {
      title: '采购发票变更 列表'
    }
  },
  edit: {
    path: '/pinvchange_edit',
    name: 'PinvChangeEdit',
    component: () => import('@/views/FinanceManagement/PinvchangeManage/PinvEdit.vue'),
    meta: {
      title: '采购发票变更 编辑'
    }
  }
};
export default pinvchangeRouter;
