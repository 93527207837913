const qcInspectionRouter = {
  list: {
    path: '/qcInspection_list',
    name: 'qcInspectionList',
    component: () => import('@/views/InspectionManagement/qcInspectionManage/QcInspectionRouterList.vue'),
    meta: {
      title: '验货控制表 列表'
    }
  }
};
export default qcInspectionRouter;
