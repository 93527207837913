const settRouter = {
  list: {
    path: '/sett_list',
    name: 'SettList',
    component: () => import('@/views/SystemManagement/SettManage/SettList.vue'),
    meta: {
      title: '参数信息列表'
    }
  }
};
export default settRouter;
