const pinvRouter = {
  list: {
    path: '/pinv_list',
    name: 'PinvList',
    component: () => import('@/views/FinanceManagement/PinvManage/PinvNewList.vue'),
    meta: {
      title: '采购发票 列表'
    }
  },
  add: {
    path: '/pinv_add',
    name: 'PinvAdd',
    component: () => import('@/views/FinanceManagement/PinvManage/PinvAdd.vue'),
    meta: {
      title: '采购发票 新增'
    }
  },
  edit: {
    path: '/pinv_edit',
    name: 'PinvEdit',
    component: () => import('@/views/FinanceManagement/PinvManage/PinvEdit.vue'),
    meta: {
      title: '采购发票 编辑'
    }
  }
};
export default pinvRouter;
