const reimRouter = {
  list: {
    path: '/reim_list',
    name: 'reimList',
    component: () => import('@/views/OfficeManagement/ReimManage/ReimList.vue'),
    meta: {
      title: '费用报销单 列表'
    }
  },
  add: {
    path: '/reim_add',
    name: 'reimAdd',
    component: () => import('@/views/OfficeManagement/ReimManage/ReimAdd.vue'),
    meta: {
      title: '费用报销单 新增'
    }
  },
  edit: {
    path: '/reim_edit',
    name: 'reimEdit',
    component: () => import('@/views/OfficeManagement/ReimManage/ReimEdit.vue'),
    meta: {
      title: '费用报销单 编辑'
    }
  }
};
export default reimRouter;
