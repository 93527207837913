const UrlEncode = {
  // 加密
  encode(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-f]{2})/g, function toSolidBytes(match, p) {
        return String.fromCharCode('0x' + p);
      })
    );
  },
  // 解密
  decode(str) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }
};
export default UrlEncode;
