const prodRouter = {
  list: {
    path: '/prod_list',
    name: 'ProdList',
    component: () => import('@/views/MessageManagement/ProdManage/ProdList.vue'),
    meta: {
      title: '成品信息 列表'
    }
  },
  add: {
    path: '/prod_add',
    name: 'ProdAdd',
    component: () => import('@/views/MessageManagement/ProdManage/ProdAdd.vue'),
    meta: {
      title: '成品信息 新增'
    }
  },
  edit: {
    path: '/prod_edit',
    name: 'ProdEdit',
    component: () => import('@/views/MessageManagement/ProdManage/ProdEdit.vue'),
    meta: {
      title: '成品信息 编辑'
    }
  }
};
export default prodRouter;
