const productDesignGroupRouter = {
  list: {
    path: '/designTeam_list',
    name: 'productDesignGroupList',
    component: () => import('@/views/DevelopManagement/ProductDesignGroupManage/ProductDesignGroupList.vue'),
    meta: {
      title: '产品设计组 列表'
    }
  },
  edit: {
    path: '/designTeam_edit',
    name: 'productDesignGroupEdit',
    component: () => import('@/views/DevelopManagement/ProductDesignGroupManage/ProductDesignGroupEdit.vue'),
    meta: {
      title: '产品设计组 编辑'
    }
  },
  add: {
    path: '/designTeam_add',
    name: 'productDesignGroupAdd',
    component: () => import('@/views/DevelopManagement/ProductDesignGroupManage/ProductDesignGroupAdd.vue'),
    meta: {
      title: '产品设计组 新增'
    }
  }
};
export default productDesignGroupRouter;
