import topic from '../topic';
import { getNoCatch } from '@api/request';
export const cpttAPI = {
  getCptts: topic.cpttTopic + '/get_cptts',
  getAllCpttsV1: topic.cpttTopic + '/get_all_cptts_v1',
  getCpttById: topic.cpttTopic + '/get_cptt_by_id',
  addCptt: topic.cpttTopic + '/add_cptt',
  editCptt: topic.cpttTopic + '/edit_cptt',
  deletCpttByIds: topic.cpttTopic + '/delete_cptt_by_ids',
  getCpttBankByCpttId: topic.cpttTopic + '/get_cptt_bank_by_cptt_id',
  getRichErpCptt: params => getNoCatch(`${topic.cpttTopic}/getricherp`, params)
};
