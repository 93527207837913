const desiRouter = {
  list: {
    path: '/desi_list',
    name: 'DesiList',
    component: () => import('@/views/DevelopManagement/DesiManage/DesiList.vue'),
    meta: {
      title: '产品设计单列表'
    }
  },
  add: {
    path: '/desi_add',
    name: 'DesiAdd',
    component: () => import('@/views/DevelopManagement/DesiManage/DesiAdd.vue'),
    meta: {
      title: '产品设计单 新增'
    }
  },
  edit: {
    path: '/desi_edit',
    name: 'DesiEdit',
    component: () => import('@/views/DevelopManagement/DesiManage/DesiEdit.vue'),
    meta: {
      title: '产品设计单 编辑'
    }
  },
  DesignStatisticalDraft: {
    path: '/designStatisticalDraft',
    name: 'DesignStatisticalDraft',
    component: () => import('@/views/DevelopManagement/DesiManage/DesignStatisticalDraftList.vue'),
    meta: {
      title: '设计图稿统计'
    }
  },
  StatisticsOfCompletedTransactions: {
    path: '/statisticsOfCompletedTransactions',
    name: 'StatisticsOfCompletedTransactions',
    component: () => import('@/views/DevelopManagement/DesiManage/StatisticsOfCompletedTransactionsList.vue'),
    meta: {
      title: '成品成交统计'
    }
  }
};
export default desiRouter;
