const domeSconRequisitionRouter = {
  list: {
    path: '/domescon_list',
    name: 'domeSconList',
    component: () => import('@/views/FinanceManagement/DomeSconRequisitionManage/DomeSconRequisitionList.vue'),
    meta: {
      title: '内贸销售开票通知 列表'
    }
  },
  edit: {
    path: '/domescon_edit',
    name: 'domeSconEdit',
    component: () => import('@/views/FinanceManagement/DomeSconRequisitionManage/DomeSconRequisitionEdit.vue'),
    meta: {
      title: '内贸销售开票通知 编辑'
    }
  }
};
export default domeSconRequisitionRouter;
