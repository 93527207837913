const quotationRouter = {
  list: {
    path: '/quotation_list',
    name: 'QuotationList',
    component: () => import('@/views/DevelopManagement/QuotationManage/QuotationList.vue'),
    meta: {
      title: '美金报价单(其他) 列表'
    }
  },
  add: {
    path: '/quotation_add',
    name: 'QuotationAdd',
    component: () => import('@/views/DevelopManagement/QuotationManage/QuotationAdd.vue'),
    meta: {
      title: '美金报价单(其他) 新增'
    }
  },
  edit: {
    path: '/quotation_edit',
    name: 'QuotationEdit',
    component: () => import('@/views/DevelopManagement/QuotationManage/QuotationEdit.vue'),
    meta: {
      title: '美金报价单(其他) 编辑'
    }
  }
};
export default quotationRouter;
