const ppayRouter = {
  list: {
    path: '/ppin_list',
    name: 'PpinList',
    component: () => import('@/views/SettleManagement/StisManage/PpinList.vue'),
    meta: {
      title: '应付账款查询 列表'
    }
  },
  listO: {
    path: '/soin_list',
    name: 'SoinList',
    component: () => import('@/views/SettleManagement/StisManage/SoinList.vue'),
    meta: {
      title: 'SH应收账款查询 列表'
    }
  },
  listT: {
    path: '/hoin_list',
    name: 'HoinList',
    component: () => import('@/views/SettleManagement/StisManage/HoinList.vue'),
    meta: {
      title: 'HK应收账款查询 列表'
    }
  },
  listF: {
    path: '/cusa_list',
    name: 'CusaList',
    component: () => import('@/views/SettleManagement/CusaManage/CusaList.vue'),
    meta: {
      title: '客户销售统计 列表'
    }
  },
  listS: {
    path: '/rpin_list',
    name: 'OfteList',
    component: () => import('@/views/SettleManagement/OfteManage/OfteList.vue'),
    meta: {
      title: '05日度明细报表'
    }
  },
  listR: {
    path: '/rqinmonth_list',
    name: 'RqinmonthList',
    component: () => import('@/views/StatisticsManagement/RqinMonthStatistics/RqinMonthStatistics.vue'),
    meta: {
      title: '05月度明细表'
    }
  },
  listQ: {
    path: '/ouin_list',
    name: 'OureList',
    component: () => import('@/views/SettleManagement/OureManage/OureList.vue'),
    meta: {
      title: '委外加工需求 列表'
    }
  }
};
export default ppayRouter;
