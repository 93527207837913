const sconRouter = {
  list: {
    path: '/scon_list',
    name: 'SconList',
    component: () => import('@/views/SalesManagement/SconManage/SconList.vue'),
    meta: {
      title: '销售合同 列表'
    }
  },
  add: {
    path: '/scon_add',
    name: 'SconAdd',
    component: () => import('@/views/SalesManagement/SconManage/SconAdd.vue'),
    meta: {
      title: '销售合同 新增'
    }
  },
  edit: {
    path: '/scon_edit',
    name: 'sconEdit',
    component: () => import('@/views/SalesManagement/SconManage/SconEdit.vue'),
    meta: {
      title: '销售合同 编辑'
    }
  },
  newWeeklyPOReport: {
    path: '/newWeeklyPOReport',
    name: 'NewWeeklyPOReport',
    component: () => import('@/views/SalesManagement/SconManage/NewWeeklyPOReport.vue'),
    meta: {
      title: 'New Weekly PO Report'
    }
  },
  barkOrders: {
    path: '/barkOrders',
    name: 'BarkOrders',
    component: () => import('@/views/SalesManagement/SconManage/BarkOrders.vue'),
    meta: {
      title: 'Bark Orders'
    }
  },
  categorySalesStatistics: {
    path: '/categorySalesStatistics',
    name: 'CategorySalesStatistics',
    component: () => import('@/views/SalesManagement/SconManage/CategorySalesStatistics.vue'),
    meta: {
      title: '品类销售统计'
    }
  },
  scon_invpush_list_9006: {
    path: '/scon_invpush_list_9006',
    name: 'scon_invpush_list_9006',
    component: () => import('@/views/SalesManagement/SconManage/TabChild/Push9006.vue'),
    meta: {
      title: '开票提醒'
    }
  }
};
export default sconRouter;
