const tinvRouter = {
  list: {
    path: '/tinv_list',
    name: 'TinvList',
    component: () => import('@/views/FinanceManagement/TinvManage/TinvList.vue'),
    meta: {
      title: '转开发票 列表'
    }
  },
  add: {
    path: '/tinv_add',
    name: 'TinvAdd',
    component: () => import('@/views/FinanceManagement/TinvManage/TinvAdd.vue'),
    meta: {
      title: '转开发票 新增'
    }
  },
  edit: {
    path: '/tinv_edit',
    name: 'TinvEdit',
    component: () => import('@/views/FinanceManagement/TinvManage/TinvEdit.vue'),
    meta: {
      title: '转开发票 编辑'
    }
  }
};
export default tinvRouter;
