const qtsoRouter = {
  list: {
    path: '/qtso_list',
    name: 'QtsoList',
    component: () => import('@/views/MessageManagement/QtsoManage/QtsoList.vue'),
    meta: {
      title: '素材信息列表'
    }
  },
  add: {
    path: '/qtso_add',
    name: 'QtsoAdd',
    component: () => import('@/views/MessageManagement/QtsoManage/QtsoAdd.vue'),
    meta: {
      title: '素材信息 新增'
    }
  },
  edit: {
    path: '/qtso_edit',
    name: 'QtsoEdit',
    component: () => import('@/views/MessageManagement/QtsoManage/QtsoEdit.vue'),
    meta: {
      title: '素材信息 编辑'
    }
  }
};
export default qtsoRouter;
