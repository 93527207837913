const spinRouter = {
  list: {
    path: '/spin_list',
    name: 'SpinList',
    component: () => import('@/views/SalesManagement/SpinManage/SpinList.vue'),
    meta: {
      title: '待出运清单 列表'
    }
  }
};
export default spinRouter;
