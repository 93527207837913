const suctRouter = {
  list: {
    path: '/outs_list',
    name: 'SuctList',
    component: () => import('@/views/SettleManagement/SuctManage/SuctList.vue'),
    meta: {
      title: '委外加工合同 列表'
    }
  },
  add: {
    path: '/outs_add',
    name: 'SuctAdd',
    component: () => import('@/views/SettleManagement/SuctManage/SuctAdd.vue'),
    meta: {
      title: '委外加工合同 新增'
    }
  },
  edit: {
    path: '/outs_edit',
    name: 'SuctEdit',
    component: () => import('@/views/SettleManagement/SuctManage/SuctEdit.vue'),
    meta: {
      title: '委外加工合同 编辑'
    }
  }
};
export default suctRouter;
