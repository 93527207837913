const consultRouter = {
  list: {
    path: '/consult_list',
    name: 'ConsultList',
    component: () => import('@/views/SalesManagement/ConsultManage/ConsultList.vue'),
    meta: {
      title: '订单咨询单 列表'
    }
  },
  edit: {
    path: '/consult_edit',
    name: 'consultEdit',
    component: () => import('@/views/SalesManagement/ConsultManage/ConsultEdit.vue'),
    meta: {
      title: '订单咨询单 编辑'
    }
  }
};
export default consultRouter;
