const macoRouter = {
  list: {
    path: '/maco_list',
    name: 'macoList',
    component: () => import('@/views/SalesManagement/MacoManage/MacoList.vue'),
    meta: {
      title: '材料咨询单 列表'
    }
  },
  edit: {
    path: '/maco_edit',
    name: 'macoEdit',
    component: () => import('@/views/SalesManagement/MacoManage/MacoEdit.vue'),
    meta: {
      title: '材料咨询单 编辑'
    }
  },
  add: {
    path: '/maco_add',
    name: 'macoAdd',
    component: () => import('@/views/SalesManagement/MacoManage/MacoAdd.vue'),
    meta: {
      title: '材料咨询单 新增'
    }
  }
};
export default macoRouter;
