const puinRoute = {
  list: {
    path: '/puin_list',
    name: 'puinList',
    meta: {
      title: '成品申购清单 列表'
    },
    component: () => import('@/views/SalesManagement/PuinManage/PuinList.vue')
  }
};

export default puinRoute;
