const inquiryRouter = {
  list: {
    path: '/inquiry_list',
    name: 'InquiryList',
    component: () => import('@/views/SalesManagement/InquiryManage/InquiryList.vue'),
    meta: {
      title: '咨询清单 列表'
    }
  },
  add: {
    path: '/inquiry_add',
    name: 'InquiryAdd',
    component: () => import('@/views/SalesManagement/InquiryManage/InquiryAdd.vue'),
    meta: {
      title: '咨询清单 新增'
    }
  },
  edit: {
    path: '/inquiry_edit',
    name: 'InquiryEdit',
    component: () => import('@/views/SalesManagement/InquiryManage/InquiryEdit.vue'),
    meta: {
      title: '咨询清单 编辑'
    }
  },
  push: {
    path: '/inqu_push_list_9005',
    name: 'inquPushList9005',
    component: () => import('@/views/SalesManagement/InquiryManage/InquPush9005.vue'),
    meta: {
      title: '咨询单提醒'
    }
  }
};
export default inquiryRouter;
