const InveRouter = {
  list: {
    path: '/inve_list',
    name: 'InveList',
    component: () => import('@/views/OfficeManagement/InvoiceVerificationManage/InveList.vue'),
    meta: {
      title: '发票验证 列表'
    }
  }
};
export default InveRouter;
