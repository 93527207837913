const apayRouter = {
  list: {
    path: '/apay_list',
    name: 'ApayList',
    component: () => import('@/views/SettleManagement/ApayManage/ApayList.vue'),
    meta: {
      title: '付款申请书 列表'
    }
  },
  add: {
    path: '/apay_add',
    name: 'ApayAdd',
    component: () => import('@/views/SettleManagement/ApayManage/ApayAdd.vue'),
    meta: {
      title: '付款申请书 新增'
    }
  },
  edit: {
    path: '/apay_edit',
    name: 'ApayEdit',
    component: () => import('@/views/SettleManagement/ApayManage/ApayEdit.vue'),
    meta: {
      title: '付款申请书 编辑'
    }
  }
};
export default apayRouter;
