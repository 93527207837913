const deliRouter = {
  list: {
    path: '/deli_list',
    name: 'deliList',
    meta: {
      title: '进仓单 列表'
    },
    component: () => import('@/views/TransportationManagement/DeliManage/DeliList.vue')
  },

  edit: {
    path: '/deli_edit',
    name: 'deliEdit',
    meta: {
      title: '进仓单 编辑'
    },
    component: () => import('@/views/TransportationManagement/DeliManage/DeliEdit.vue')
  }
};

export default deliRouter;
