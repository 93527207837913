const usquRouter = {
  list: {
    path: '/usqu_list',
    name: 'UsquList',
    component: () => import('@/views/DevelopManagement/UsquManage/UsquList.vue'),
    meta: {
      title: '美金报价单(五部) 列表'
    }
  },
  add: {
    path: '/usqu_add',
    name: 'UsquAdd',
    component: () => import('@/views/DevelopManagement/UsquManage/UsquForm.vue'),
    meta: {
      title: '美金报价单(五部) 新增'
    }
  },
  edit: {
    path: '/usqu_edit',
    name: 'UsquEdit',
    component: () => import('@/views/DevelopManagement/UsquManage/UsquForm.vue'),
    meta: {
      title: '美金报价单(五部) 编辑'
    }
  }
};
export default usquRouter;
