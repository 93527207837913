const DTdeliRouter = {
  list: {
    path: '/DTdeli_list',
    name: 'DTdeliList',
    component: () => import('@/views/TransportationManagement/DTdeliManage/DTdeliList.vue'),
    meta: {
      title: '内贸进仓单 列表'
    }
  },
  edit: {
    path: '/DTdeli_edit',
    name: 'DTdeliEdit',
    component: () => import('@/views/TransportationManagement/DTdeliManage/DTdeliEdit.vue'),
    meta: {
      title: '内贸进仓单 编辑'
    }
  }
};
export default DTdeliRouter;
