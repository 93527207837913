import Vue from 'vue';
import Vuex from 'vuex';
import { getDateNoTime } from '@assets/js/dateUtils';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pageSize: 20,
    selection: { prop: 'selection', type: 'selection', labelWidth: '48px' },
    index: { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
    stff_name: { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: '120px', sortable: false },
    create_time: {
      prop: 'create_time',
      label: '录入时间',
      itemType: 'date',
      labelWidth: '120px',
      formatter: val => getDateNoTime(val, true)
    },
    status: {
      prop: 'status',
      label: '单据状态',
      itemType: 'select',
      fixed: 'right',
      options: [
        { value: 0, label: '草拟' },
        { value: 1, label: '在批' },
        { value: 2, label: '生效' }
      ]
    },
    prodInfoSyncData: {},
    businessDept: [],
    domesticTradeDept: [],
    commerceDept: [],
    purchaseDept: [],
    accessoriesDept: []
  },
  mutations: {
    setProdInfoSyncData(state, data) {
      state.prodInfoSyncData = data;
    },
    setBusinessDept(state, data) {
      state.businessDept = data;
    },
    setDomesticTradeDept(state, data) {
      state.domesticTradeDept = data;
    },
    setCommerceDept(state, data) {
      state.commerceDept = data;
    },
    setPurchaseDept(state, data) {
      state.purchaseDept = data;
    },
    setAccessoriesDept(state, data) {
      state.accessoriesDept = data;
    }
  },
  actions: {},
  modules: {}
});
