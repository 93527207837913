const deliProdRouter = {
  list: {
    path: '/deliProd_list',
    name: 'DeliProdList',
    component: () => import('@/views/TransportationManagement/DeliProdManage/DeliProdList.vue'),
    meta: {
      title: '进仓单汇总'
    }
  }
};
export default deliProdRouter;
