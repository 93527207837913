const apprRouter = {
  list: {
    path: '/appr_list',
    name: 'ApprList',
    component: () => import('@/views/SystemManagement/ApprManage/ApprList.vue'),
    meta: {
      title: '审批流列表'
    }
  },
  edit: {
    path: '/appr_edit',
    name: 'ApprEdit',
    component: () => import('@/views/SystemManagement/ApprManage/ApprEdit.vue'),
    meta: {
      title: '编辑审批流'
    }
  },
  toApproved: {
    path: 'toApproved_all',
    name: 'ApprovedAll',
    component: () => import('@/views/component/boardCommon/boardAllLeft.vue'),
    meta: {
      title: '需审批全部'
    }
  },
  beApproved: {
    path: 'beApproved_all',
    name: 'beApprovedAll',
    component: () => import('@/views/component/boardCommon/boardAllRight.vue'),
    meta: {
      title: '被审批全部'
    }
  },
  toApprovalRecord: {
    path: 'toApproval_record',
    name: 'ApprovalRecord',
    component: () => import('@/views/component/boardCommon/ApprovalRecord.vue'),
    meta: {
      title: '审批记录'
    }
  }
};
export default apprRouter;
