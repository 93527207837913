const modcRouter = {
  list: {
    path: '/modc_list',
    name: 'ModcList',
    component: () => import('@/views/SalesManagement/ModcManage/ModcList.vue'),
    meta: {
      title: '材料采购变更单 列表'
    }
  },
  add: {
    path: '/modc_add',
    name: 'ModcAdd',
    component: () => import('@/views/SalesManagement/ModcManage/ModcAdd.vue'),
    meta: {
      title: '材料采购变更单 新增'
    }
  },
  edit: {
    path: '/modc_edit',
    name: 'ModcEdit',
    component: () => import('@/views/SalesManagement/ModcManage/ModcEdit.vue'),
    meta: {
      title: '材料采购变更单 编辑'
    }
  }
};
export default modcRouter;
