const actualRouter = {
  list: {
    path: '/actual_list',
    name: 'actualList',
    component: () => import('@/views/FinanceManagement/ActualManage/ActualList.vue'),
    meta: {
      title: '采购发票实际收票 列表'
    }
  }
};
export default actualRouter;
