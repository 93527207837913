import topic from '../topic';
import { getNoCatch } from '@api/request';
export const suppAPI = {
  getSupps: topic.suppTopic + '/get_supps',
  getSuppById: topic.suppTopic + '/get_supp_by_id',
  addSupp: topic.suppTopic + '/add_supp',
  editSupp: topic.suppTopic + '/edit_supp',
  deleteSuppByIds: topic.suppTopic + '/delete_supp_by_ids',
  getSuppsV1: topic.suppTopic + '/get_supps_v1',
  getDyjSuppsV1: topic.suppTopic + '/get_dyj_supps_v1',
  getSuppByBeloDeptId: topic.suppTopic + '/get_supp_by_belo_dept_id',
  getMesAccountSetInfo: topic.suppTopic + '/getMesAccountSetInfo',
  getRichErpSupp: params => getNoCatch(`${topic.suppTopic}/getRichErpSupp`, params)
};
