const permRouter = {
  list: {
    path: '/perm_list',
    name: 'PermList',
    component: () => import('@/views/SystemManagement/PermManage/PermList.vue'),
    meta: {
      title: '权限信息'
    }
  }
};
export default permRouter;
