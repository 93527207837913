const fequRouter = {
  list: {
    path: '/fequ_list',
    name: 'FequList',
    component: () => import('@/views/DevelopManagement/FequManage/FequList.vue'),
    meta: {
      title: '工厂打样单列表'
    }
  },
  edit: {
    path: '/fequ_edit',
    name: 'FequEdit',
    component: () => import('@/views/DevelopManagement/FequManage/FequEdit.vue'),
    meta: {
      title: '工厂打样单 编辑'
    }
  },
  makeSampleControlTable: {
    path: '/makeSampleControlTable',
    name: 'MakeSampleControlTable',
    // path: '/fequSample_list',
    // name: 'fequSample_list',
    component: () => import('@/views/DevelopManagement/FequManage/MakeSampleControlTableList.vue'),
    meta: {
      title: '打样控制表'
    }
  },
  push: {
    path: '/fequ_push_list_9003',
    name: 'fequPushList9003',
    component: () => import('@/views/DevelopManagement/FequManage/FequPush9003.vue'),
    meta: {
      title: 'DYJ退单提醒'
    }
  },
  push9009: {
    path: '/push_9009',
    name: '/push_9009',
    component: () => import('@/views/DevelopManagement/FequManage/FequPush9009.vue'),
    meta: {
      title: 'DYJ接收提醒'
    }
  }
};
export default fequRouter;
