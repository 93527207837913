import { optnAPI } from '@api/modules/optn';
import axios from 'axios';
import { suppAPI } from '@api/modules/supp';
import { custAPI } from '@api/modules/cust';
import { deptAPI } from '@api/modules/department';
import { stffAPI } from '@api/modules/staff';
import { cpttAPI } from '@api/modules/comptitle';
import { cloneDeep } from 'lodash';
import { Message } from 'element-ui';

export const changeStaffForm = (staffForm, dataForm) => {
  staffForm.stff_name = dataForm.stff_name;
  staffForm.dept_name = dataForm.dept_name;
  staffForm.dept_team_name = dataForm.dept_team_name;
  staffForm.user_id = dataForm.user_id;
  staffForm.dept_id = dataForm.dept_id;
  staffForm.stff_id = dataForm.stff_id;
  return staffForm;
};
/**
 * @param {{}} form 表单
 * @param {[[string|number]]} dateArray 二维日期范围数组
 * @param {[string]} columns 搜索参数数组
 */
export const searchTimeRangeFormatter = (form, dateArray, columns = ['start_time', 'end_time']) => {
  let columnsIndex = 0;
  dateArray.forEach(dateRange => {
    let arr = dateRange || [null, null];
    for (let i = 0; i < arr.length; i++) {
      form[columns[columnsIndex]] = arr[0];
      form[columns[columnsIndex + 1]] = arr[1];
    }
    columnsIndex += 2;
  });
  return cloneDeep(form);
};
/**
 * 获取单位
 * @param {string} type 返回数据格式类型
 * @param {string} param 返回数据格式类型中的取值字段 默认为param2
 * @param {string} other param 返回数据格式类型中的其他字段
 * @return {Promise<{label: *, value: *}[]|{id: {label: *, value: *}}|*[]>}
 */
export const getUnit = async (type, param = 'param2', other) => {
  let { data } = await axios.get(optnAPI.getOptnByPermId, { params: { perm_id: 10008 } });
  if (data.code === 0)
    switch (type) {
      case 'select':
        return Array.from([...data.data.form.optn_cntt_list], item => {
          let temp = { value: item[param], label: item[param] };
          if (other) temp[other] = item[other];
          return temp;
        });
      case 'valueList':
        let temp = {};
        data.data.form.optn_cntt_list.forEach(item => {
          temp[item.id] = { value: item[param], label: item[param] };
        });
        return temp;
    }
  else return [];
};
/**
 * 获取供应商
 * @param {string} type 返回数据格式类型
 * @param {[string]} other 获取除 id 和 abbr 外其他字段至返回数据格式类型中
 * @param {string} label 返回数据格式类型中 label 的字段取值
 * @param {{}} obj 查询供应商的参数
 * @return {Promise<{label: *, value}[]|{id: {label: *, value: *}}|*[]|({}|*[])[]>}
 */
export const getSupp = async (type = 'default', other = [], label = 'supp_abbr', obj) => {
  let { data } = await axios.get(suppAPI.getSuppsV1, { params: obj });
  if (data.code === 0) {
    switch (type) {
      case 'default':
        return Array.from([...data.data], item => {
          let temp = { value: item.supp_id, label: item[label] };
          other.forEach(o => (temp[o] = item[o]));
          return temp;
        });
      case 'valueList':
        let temp = {};
        data.data.forEach(item => {
          let otherObj = { value: item.supp_id, label: item[label] };
          other.forEach(o => (otherObj[o] = item[o]));
          temp[item.supp_id] = otherObj;
        });
        return temp;
      case 'valueListAndDefault':
        let valueListObj = {};
        let valueListArr = [];
        data.data.forEach(item => {
          let temp = { value: item.supp_id, label: item[label] };
          valueListObj[item.supp_id] = temp;
          valueListArr.push(temp);
        });
        return [valueListObj, valueListArr];
      //拼接供应商编码的方法
      case 'valueAndNoListAndDefault':
        let valueListObj2 = {};
        let valueListArr2 = [];
        data.data.forEach(item => {
          let temp = { value: item.supp_id, label: item[label] + '(' + item['supp_no'] + ')' };
          valueListObj2[item.supp_id] = temp;
          valueListArr2.push(temp);
        });
        return [valueListObj2, valueListArr2];
    }
  } else return [];
};
/**
 * 获取客户信息
 * @param {number} status 生效状态
 * @param {string} type 返回数据格式类型
 * @param {[string]} names 多个客户数组
 * @param {boolean} unprocessedData 是否需要未处理数据
 * @param specifyCustApi 指定API
 * @param otherRequestParams 其他请求参数
 * @return {Promise<{label: *, value: *}[]|{id: {label: *, value: *}}|{label: *, value: *}[]|*[]|({}|*[])[]>}
 */
export const getCustomerInfo = async ({ status, type, names, unprocessedData, specifyCustApi, otherRequestParams }) => {
  let isArr = Array.isArray(names);
  let res = isArr
    ? await axios.post(custAPI.get_all_custs_names, { cust_names: names, status: status })
    : await axios.get(specifyCustApi || custAPI.getAllCustsV1, { params: { status: status, ...otherRequestParams } });
  if (res.data.code === 0) {
    let result = [];
    switch (type) {
      case 'abbr':
        result = Array.from(res.data.data.list, ({ cust_id, cust_abbr }) => {
          return { value: cust_id, label: cust_abbr };
        });
        break;
      case 'name':
        result = Array.from(res.data.data.list, ({ cust_id, cust_name }) => {
          return { value: cust_id, label: cust_name };
        });
        break;
      case 'valueList':
        let temp = {};
        res.data.data.list.forEach(({ cust_id, cust_abbr }) => {
          temp[cust_id] = { value: cust_id, label: cust_abbr };
        });
        result = temp;
        break;
      case 'valueListAndDefault':
        let valueListObj = {};
        let valueListArr = [];
        res.data.data.forEach(({ cust_id, cust_abbr }) => {
          let temp = { value: cust_id, label: cust_abbr };
          valueListObj[cust_id] = temp;
          valueListArr.push(temp);
        });
        result = [valueListObj, valueListArr];
        break;
    }
    return unprocessedData ? { unprocessedData: res.data.data.list, list: result } : result;
  } else return [];
};
/**
 * 获取价格条款
 * @param {string} type
 * @return {Promise<{label: *, value: *}[]|*[]>}
 */
export const getPriceTerms = async (type = 'select') => {
  let res = await axios.get(optnAPI.getOptnByPermId, { params: { perm_id: 10014 } });
  if (res.data.code === 0) {
    switch (type) {
      case 'select':
        return Array.from([...res.data.data.form.optn_cntt_list], ({ param1 }) => {
          return { value: param1, label: param1 };
        });
    }
  } else return [];
};
/**
 *
 * @param type
 * @return {Promise<{id: {label: *, value: *}}|{label: *, value: *}[]|*[]>}
 */
export const getPort = async (type = 'select') => {
  let res = await axios.get(optnAPI.getOptnByPermId, { params: { perm_id: 10018 } });
  if (res.data.code === 0) {
    switch (type) {
      case 'select':
        return Array.from([...res.data.data.form.optn_cntt_list], ({ param1 }) => {
          return { value: param1, label: param1 };
        });
      case 'valueList':
        let temp = {};
        res.data.data.form.optn_cntt_list.forEach(({ id, param1 }) => {
          temp[id] = { value: param1, label: param1 };
        });
        return temp;
    }
  } else return [];
};
/**
 * 获取部门
 * @param name
 * @param type
 * @param specifyValue
 * @return {Promise<{id: {label: *, value: *}}|{label: *, value: *}[]|*[]>}
 */
export const getDept = async (name = '', type = 'default', specifyValue = 'dept_id') => {
  let isArr = Array.isArray(name);
  let res = await axios.get(name ? (isArr ? deptAPI.getDeptByNames : deptAPI.getDeptByName) : deptAPI.getAllDeptsV1, {
    params: { dept_name: name.toString() }
  });
  if (res.data.code === 0) {
    switch (type) {
      case 'default':
        return Array.from(res.data.data, item => {
          return { value: item[specifyValue], label: item.dept_name };
        });
      case 'valueList':
        let temp = {};
        res.data.data.forEach(({ dept_id, dept_name }) => {
          temp[dept_id] = { value: dept_id, label: dept_name };
        });
        return temp;
      case 'valueListAndDefault':
        let valueListObj = {};
        let valueListArr = [];
        res.data.data.forEach(({ dept_id, dept_name }) => {
          let temp = { value: dept_id, label: dept_name };
          valueListObj[dept_id] = temp;
          valueListArr.push(temp);
        });
        return [valueListObj, valueListArr];
      case 'ids':
        return Array.from(res.data.data, ({ dept_id }) => dept_id);
      case 'managerIds':
        return Array.from(res.data.data, ({ stff_id }) => stff_id);
    }
  } else return [];
};
/**
 * 获取员工
 * @param {string} name 指定部门
 * @param {string} type 返回数据类型
 * @param {string} stff_name 指定员工
 * @param {string} specialValue 特殊指定value字段
 * @return {Promise<({}|*[])[]|{id: {label: *, value: *}}|{label: *, value: *}[]|*[]>}
 */
export const getStff = async (name = '', type = 'default', stff_name = '', specialValue = '') => {
  let res = await axios.get(name ? stffAPI.getStffByDeptName : stffAPI.getAllStffsV1, {
    params: { dept_name: name, stff_name: stff_name }
  });
  if (res.data.code === 0) {
    switch (type) {
      case 'default':
        return Array.from(res.data.data, item => {
          return { value: item[specialValue ? specialValue : 'stff_id'], label: item.stff_name };
        });
      case 'valueList':
        let valueList = {};
        res.data.data.forEach(({ stff_id, stff_name }) => {
          valueList[stff_id] = { value: stff_id, label: stff_name };
        });
        return valueList;
      case 'valueListAndDefault':
        let valueListObj = {};
        let valueListArr = [];
        res.data.data.forEach(({ stff_id, stff_name }) => {
          let temp = { value: stff_id, label: stff_name };
          valueListObj[stff_id] = temp;
          valueListArr.push(temp);
        });
        return [valueListObj, valueListArr];
    }
  } else return [];
};
/**
 * 获取公司
 * @return {Promise<{label: *, value: *}[]|*[]>}
 */
export const getCptt = async () => {
  let res = await axios.get(cpttAPI.getAllCpttsV1);
  if (res.data.code === 0) {
    return Array.from(res.data.data, ({ cptt_id, cptt_name }) => {
      return { value: cptt_id, label: cptt_name };
    });
  } else return [];
};

/**
 * 根据id获取数据中心数据(通用)
 * @param {number} id perm_id
 * @param {string} column 字段名，默认为'param1'
 * @param {string} type 返回类型，默认为'no'，可选值为'no'|'select'
 * @param {string} otherColumn 额外字段
 * @param repalceApi
 */
export const getDataCenterByPermId = async ({ id, column = 'param1', type = 'no', otherColumn = '', repalceApi }) => {
  let res = await axios.get(repalceApi ? repalceApi : optnAPI.getOptnByPermId, { params: { perm_id: id } });
  if (res.data.code === 0) {
    const optnCnttList = res.data.data.form.optn_cntt_list;
    switch (type) {
      case 'no':
        return optnCnttList;
      case 'select':
        return optnCnttList.map(item => ({
          value: item[column],
          label: item[column],
          ...(otherColumn && { [otherColumn]: item[otherColumn] })
        }));
      case 'valueList':
        let valueList = {};
        optnCnttList.forEach(item => {
          valueList[item[column]] = { value: item[column], label: item[column] };
        });
        return valueList;
      case 'valueListAndDefault':
        let valueListObj = {};
        let valueListArr = [];
        optnCnttList.forEach(item => {
          let temp = { value: item[column], label: item[column], ...(otherColumn && { [otherColumn]: item[otherColumn] }) };
          valueListObj[item[column]] = temp;
          valueListArr.push(temp);
        });
        return [valueListObj, valueListArr];
    }
  } else return [];
};
/**
 * 下载文件
 * @param {string} fileUrl 文件url
 * @param {string} fileName 文件名
 * @param {string} type 类型
 * @param {boolean} open 是否是打开 默认不打开
 */
export const downloadFile = async ({ fileUrl, fileName, type = '', open = false }) => {
  fileUrl = fileUrl.indexOf('manenvip') === -1 ? process.env.VUE_APP_FILE_URL + fileUrl + type : '' + fileUrl + type;
  if (open) return window.open(fileUrl);
  try {
    const headers = new Headers({ 'Content-Disposition': 'attachment' });
    const response = await fetch(fileUrl, { method: 'GET', headers });
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName; // 设置下载的文件名
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('文件下载失败:', error);
  }
};
/**
 * 下载文件的异步函数。
 * @param {Object} options - 配置对象。
 * @param {string} options.fileUrl - 文件URL。
 * @param {string} options.fileName - 下载时显示的文件名。
 * @param {string} [options.type=''] - 文件类型后缀，如有需要。
 * @param {boolean} [options.open=false] - 是否直接在新窗口打开文件而非下载。
 */
export const downloadFile1 = async ({ fileUrl, fileName, type = '', open = false }) => {
  try {
    const constructedUrl = constructFileUrl(fileUrl, type);

    if (open) {
      window.open(constructedUrl);
      return;
    }
    const headers = new Headers({ 'Content-Disposition': 'attachment' });
    const response = await fetch(constructedUrl, { method: 'GET', headers });
    if (!response.ok) {
      throw new Error(`文件下载失败，状态码：${response.status}`);
    }

    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href); // 清理URL对象，避免内存泄漏
  } catch (error) {
    console.error('文件下载失败:', error.message);
  }
};

/**
 * 构建完整的文件URL。
 * @param {string} fileUrl - 基础文件URL。
 * @param {string} type - 文件类型后缀。
 * @returns {string} 完整的文件URL。
 */
const constructFileUrl = (fileUrl, type) => {
  if (fileUrl.indexOf('manenvip') === -1) {
    return `${process.env.VUE_APP_FILE_URL}${fileUrl}${type}`;
  }
  return `${fileUrl}${type}`;
};
/**
 * 设置表单中开始和结束时间的值，根据需要转换时间单位。
 * @param {Object} searchForm - 目标表单对象。
 * @param {string} startTimeKey - 开始时间的键名。
 * @param {string} endTimeKey - 结束时间的键名。
 * @param {Array<number>} [dateRange=[]] - 日期范围数组，包含两个元素[开始时间, 结束时间]。
 * @param {boolean} [convertToSeconds=true] - 是否将毫秒转换为秒。
 */
export const setTime = (searchForm, startTimeKey, endTimeKey, dateRange = [], convertToSeconds = true) => {
  const [startTime, endTime] = dateRange;
  // 确保时间戳被正确赋值且进行单位转换（如果需要）
  searchForm[startTimeKey] = startTime ? (convertToSeconds ? startTime / 1000 : startTime) : null;
  searchForm[endTimeKey] = endTime ? (convertToSeconds ? endTime / 1000 : endTime) : null;
};

/**
 * 计算给定日期所在月份的最后一天的时间戳
 * @param {number} timestamp - 时间戳
 * @returns {number} 当月最后一天的时间戳
 */
function getLastDayOfMonth(timestamp) {
  let date = new Date(timestamp);
  date.setMonth(date.getMonth() + 1);
  date.setDate(0); // 设为0可得到上个月的最后一天
  return date.getTime();
}

/**
 * 计算给定日期所在月份的第一天的时间戳
 * @param {number} timestamp - 时间戳
 * @returns {number} 当月第一天的时间戳
 */
function getFirstDayOfMonth(timestamp) {
  let date = new Date(timestamp);
  date.setDate(1);
  return date.getTime();
}

export const pasteImage = ({ ref, index }) => {
  navigator.clipboard.read().then(async items => {
    const types = items[0].types[0];
    if (!types.toString().includes('image')) return Message.error('粘贴内容非图片');
    let file = new File([await items[0].getType(items[0].types[0])], types.replace('/', '.'), { type: types });
    ref.handleStart(file); // 将粘贴过来的图片加入预上传队列
    ref.submit(); // 提交图片上传队列
  });
};
