const mbinRoute = {
  list: {
    path: '/mbin_list',
    name: 'mbinList',
    meta: {
      title: '材料申购清单 列表'
    },
    component: () => import('@/views/SalesManagement/MbinManage/MbinList.vue')
  }
};

export default mbinRoute;
