const catpRouter = {
  list: {
    path: '/catp_list',
    name: 'CatpList',
    component: () => import('@/views/SalesManagement/CatpManage/CatpList.vue'),
    meta: {
      title: '猫草表单 列表'
    }
  }
};
export default catpRouter;
