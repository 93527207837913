const applicationRouter = {
  list: {
    path: '/inspecationprod_list',
    name: 'ApplicationList',
    component: () => import('@/views/InspectionManagement/InspectionApplicationManage/ApplicationList.vue'),
    meta: {
      title: '成品验货工单 列表'
    }
  },
  edit: {
    path: '/inspecationprod_edit',
    name: 'ApplicationEdit',
    component: () => import('@/views/InspectionManagement/InspectionApplicationManage/ApplicationEdit.vue'),
    meta: {
      title: '成品验货工单 编辑'
    }
  },
  add: {
    path: '/inspecationprod_add',
    name: 'ApplicationAdd',
    component: () => import('@/views/InspectionManagement/InspectionApplicationManage/ApplicationAdd.vue'),
    meta: {
      title: '成品验货工单 新增'
    }
  }
};
export default applicationRouter;
