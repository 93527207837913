const custRouter = {
  list: {
    path: '/cust_list',
    name: 'CustList',
    component: () => import('@/views/MessageManagement/CustManage/CustList.vue'),
    meta: {
      title: '客户信息 列表'
    }
  },
  add: {
    path: '/cust_add',
    name: 'CustAdd',
    component: () => import('@/views/MessageManagement/CustManage/CustAdd.vue'),
    meta: {
      title: '客户信息 新增'
    }
  },
  edit: {
    path: '/cust_edit',
    name: 'CustEdit',
    component: () => import('@/views/MessageManagement/CustManage/CustEdit.vue'),
    meta: {
      title: '客户信息 编辑'
    }
  }
};
export default custRouter;
