const RequRouter = {
  list: {
    path: '/requ_list',
    name: 'requList',
    component: () => import('@/views/DevelopManagement/RequManage/RequList.vue'),
    meta: {
      title: '委托打样单列表'
    }
  },
  add: {
    path: '/requ_add',
    name: 'requAdd',
    component: () => import('@/views/DevelopManagement/RequManage/RequAdd.vue'),
    meta: {
      title: '委托打样单 新增'
    }
  },
  edit: {
    path: '/requ_edit',
    name: 'requEdit',
    component: () => import('@/views/DevelopManagement/RequManage/RequEdit.vue'),
    meta: {
      title: '委托打样单 编辑'
    }
  },
  push: {
    path: '/requ_push_list_9002',
    name: 'requPushList9002',
    component: () => import('@/views/DevelopManagement/RequManage/RequPush9002.vue'),
    meta: {
      title: '委托打样提醒'
    }
  },
  push1: {
    path: '/requ_push_list_9004',
    name: 'requPushList9004',
    component: () => import('@/views/DevelopManagement/RequManage/RequPush9004.vue'),
    meta: {
      title: '外销取消打样/退样提醒'
    }
  },
  push9007: {
    path: '/requ_infompush_list_9007',
    name: 'requ_infompush_list_9007',
    component: () => import('@/views/DevelopManagement/RequManage/Push9007.vue'),
    meta: {
      title: '样品信息更新提醒'
    }
  },
  push9008: {
    path: '/push_9008',
    name: 'push_9008',
    component: () => import('@/views/DevelopManagement/RequManage/Push9008.vue'),
    meta: {
      title: '委托打样退回提醒'
    }
  },
  requBackDetail: {
    path: '/requ_back_detail',
    name: 'requ_back_detail',
    component: () => import('@/views/DevelopManagement/RequManage/RequBackDetail.vue'),
    meta: {
      title: '退单明细'
    }
  }
};
export default RequRouter;
