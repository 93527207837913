const applicationMtrbRouter = {
  list: {
    path: '/inspecationmtrb_list',
    name: 'ApplicationMtrbList',
    component: () => import('@/views/InspectionManagement/InspectionApplicationMtrbManage/ApplicationMtrbList.vue'),
    meta: {
      title: '材料验货工单 列表'
    }
  },
  edit: {
    path: '/inspecationmtrb_edit',
    name: 'ApplicationMtrbEdit',
    component: () => import('@/views/InspectionManagement/InspectionApplicationMtrbManage/ApplicationMtrbEdit.vue'),
    meta: {
      title: '材料验货工单 编辑'
    }
  },
  add: {
    path: '/inspecationmtrb_add',
    name: 'ApplicationMtrbAdd',
    component: () => import('@/views/InspectionManagement/InspectionApplicationMtrbManage/ApplicationMtrbAdd.vue'),
    meta: {
      title: '材料验货工单 新增'
    }
  }
};
export default applicationMtrbRouter;
