const informRouter = {
  list: {
    path: '/inform_list',
    name: 'InformList',
    component: () => import('@/views/CollectionManagement/InformManage/InformList.vue'),
    meta: {
      title: '托收信息 列表'
    }
  },
  edit: {
    path: '/inform_edit',
    name: 'InformEdit',
    component: () => import('@/views/CollectionManagement/InformManage/InformEdit.vue'),
    meta: {
      title: '托收信息 编辑'
    }
  }
};
export default informRouter;
