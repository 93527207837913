const priceConsultRouter = {
  list: {
    path: '/price_consult_list',
    name: 'PriceConsultList',
    component: () => import('@/views/SalesManagement/PriceConsultManage/PriceConsultList.vue'),
    meta: {
      title: '价格咨询单 列表'
    }
  },
  edit: {
    path: '/price_consult_edit',
    name: 'PriceConsultEdit',
    component: () => import('@/views/SalesManagement/PriceConsultManage/PriceConsultEdit.vue'),
    meta: {
      title: '价格咨询单 编辑'
    }
  }
};
export default priceConsultRouter;
