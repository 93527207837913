const leavRouter = {
  list: {
    path: '/leav_list',
    name: 'LeavList',
    component: () => import('@/views/OfficeManagement/LeavManage/LeavList.vue'),
    meta: {
      title: '请假信息列表'
    }
  },
  add: {
    path: '/leav_add',
    name: 'LeavAdd',
    component: () => import('@/views/OfficeManagement/LeavManage/LeavAdd.vue'),
    meta: {
      title: '请假信息 新增'
    }
  },
  edit: {
    path: '/leav_edit',
    name: 'LeavEdit',
    component: () => import('@/views/OfficeManagement/LeavManage/LeavEdit.vue'),
    meta: {
      title: '请假信息 编辑'
    }
  }
};
export default leavRouter;
