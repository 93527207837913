const hegiRouter = {
  list: {
    path: '/hegi_list',
    name: 'HegiList',
    component: () => import('@/views/FinanceManagement/HegiManage/HegiList.vue'),
    meta: {
      title: '收款登记单 列表'
    }
  },
  add: {
    path: '/hegi_add',
    name: 'HegiAdd',
    component: () => import('@/views/FinanceManagement/HegiManage/HegiAdd.vue'),
    meta: {
      title: '收款登记单 新增'
    }
  },
  edit: {
    path: '/hegi_edit',
    name: 'HegiEdit',
    component: () => import('@/views/FinanceManagement/HegiManage/HegiEdit.vue'),
    meta: {
      title: '收款登记单 编辑'
    }
  }
};
export default hegiRouter;
