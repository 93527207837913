const packRouter = {
  list: {
    path: '/pack_list',
    name: 'packList',
    meta: {
      title: '拖箱单 列表'
    },
    component: () => import('@/views/TransportationManagement/PackManage/PackList.vue')
  },

  edit: {
    path: '/pack_edit',
    name: 'packEdit',
    meta: {
      title: '拖箱单 编辑'
    },
    component: () => import('@/views/TransportationManagement/PackManage/PackEdit.vue')
  }
};

export default packRouter;
