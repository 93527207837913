const suppRouter = {
  list: {
    path: '/supp_list',
    name: 'SuppList',
    component: () => import('@/views/MessageManagement/SuppManage/SuppList.vue'),
    meta: {
      title: '供应商信息 列表'
    }
  },
  add: {
    path: '/supp_add',
    name: 'SuppAdd',
    component: () => import('@/views/MessageManagement/SuppManage/SuppAdd.vue'),
    meta: {
      title: '供应商信息 新增'
    }
  },
  edit: {
    path: '/supp_edit',
    name: 'SuppEdit',
    component: () => import('@/views/MessageManagement/SuppManage/SuppEdit.vue'),
    meta: {
      title: '供应商信息 编辑'
    }
  }
};
export default suppRouter;
