<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style scoped lang="scss">
@import 'assets/css/main.css';
@import 'assets/css/color-dark.css';
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
::v-deep .el-textarea__inner {
  font-size: 13px;
  font-family: '微软雅黑', serif;
}
::v-deep .el-input__inner {
  font-family: '微软雅黑', serif;
}
body {
  margin: 0px;
}

.el-textarea__inner {
  padding-right: 50px !important;
  overflow: hidden !important;
}
.el-textarea .el-input__count {
  background: #fff0 !important;
}
</style>
