const sconOrderRouter = {
  list: {
    path: '/sconorder_list',
    name: 'SconOrderList',
    component: () => import('@/views/SalesManagement/SconChangeManage/SconList.vue'),
    meta: {
      title: '销售合同变更单 列表'
    }
  },
  add: {
    path: '/sconorder_add',
    name: 'SconOrderAdd',
    component: () => import('@/views/SalesManagement/SconChangeManage/SconAdd.vue'),
    meta: {
      title: '销售合同变更单 新增'
    }
  },
  edit: {
    path: '/sconorder_edit',
    name: 'sconOrderEdit',
    component: () => import('@/views/SalesManagement/SconChangeManage/SconEdit.vue'),
    meta: {
      title: '销售合同变更单 编辑'
    }
  }
};
export default sconOrderRouter;
