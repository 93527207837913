const fequSampleRouter = {
  list: {
    path: '/fequSample_list',
    name: 'FequSampleList',
    component: () => import('@/views/DevelopManagement/FequManage/MakeSampleControlTableList.vue'),
    meta: {
      title: '打样控制表'
    }
  }
};
export default fequSampleRouter;
