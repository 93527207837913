const mouldRouter = {
  list: {
    path: '/mould_list',
    name: 'mouldList',
    component: () => import('@/views/MessageManagement/MouldManage/MouldList.vue'),
    meta: {
      title: '模具信息 列表'
    }
  }
};
export default mouldRouter;
