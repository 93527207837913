const hollRouter = {
  list: {
    path: '/holl_list',
    name: 'HollList',
    component: () => import('@/views/FinanceManagement/HollManage/HollList.vue'),
    meta: {
      title: '收款单 列表'
    }
  },
  edit: {
    path: '/holl_edit',
    name: 'HollEdit',
    component: () => import('@/views/FinanceManagement/HollManage/HollEdit.vue'),
    meta: {
      title: '收款单 编辑'
    }
  }
};
export default hollRouter;
