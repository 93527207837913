const qtucRouter = {
  list: {
    path: '/qutc_list',
    name: 'QtucList',
    component: () => import('@/views/DevelopManagement/QtucManage/QtucList.vue'),
    meta: {
      title: '简易报价单列表'
    }
  },
  edit: {
    path: '/qutc_edit',
    name: 'QtucEdit',
    component: () => import('@/views/DevelopManagement/QtucManage/QtucEdit.vue'),
    meta: {
      title: '简易报价单 编辑'
    }
  }
};
export default qtucRouter;
