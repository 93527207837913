const rqutRouter = {
  list: {
    path: '/rqut_list',
    name: 'rqutList',
    component: () => import('@/views/DevelopManagement/RqutManage/RqutNewList.vue'),
    meta: {
      title: '询价单列表'
    }
  },
  add: {
    path: '/rqut_add',
    name: 'rqutAdd',
    component: () => import('@/views/DevelopManagement/RqutManage/RqutForm.vue'),
    meta: {
      title: '询价单 新增'
    }
  },
  edit: {
    path: '/rqut_edit',
    name: 'rqutEdit',
    component: () => import('@/views/DevelopManagement/RqutManage/RqutForm.vue'),
    meta: {
      title: '询价单 编辑'
    }
  }
};

export default rqutRouter;
