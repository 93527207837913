const mtrbRouter = {
  list: {
    path: '/mtrb_list',
    name: 'MtrbList',
    component: () => import('@/views/MessageManagement/MtrbManage/MtrbList.vue'),
    meta: {
      title: '材料信息 列表'
    }
  },
  add: {
    path: '/mtrb_add',
    name: 'MtrbAdd',
    component: () => import('@/views/MessageManagement/MtrbManage/MtrbAdd.vue'),
    meta: {
      title: '材料信息 新增'
    }
  },
  edit: {
    path: '/mtrb_edit',
    name: 'MtrbEdit',
    component: () => import('@/views/MessageManagement/MtrbManage/MtrbEdit.vue'),
    meta: {
      title: '材料信息 编辑'
    }
  }
};
export default mtrbRouter;
