const tranRouter = {
  list: {
    path: '/tran_list',
    name: 'tarnList',
    meta: {
      title: '出运信息 列表'
    },
    component: () => import('@/views/TransportationManagement/TranManage/TranList.vue')
  },

  edit: {
    path: '/tran_edit',
    name: 'tranEdit',
    meta: {
      title: '出运信息 编辑'
    },
    component: () => import('@/views/TransportationManagement/TranManage/TranEdit.vue')
  },
  //生成出运管理
  add: {
    path: '/tran_add',
    name: 'tranAdd',
    meta: {
      title: '出运信息 添加'
    },
    component: () => import('@/views/TransportationManagement/TranManage/TranAdd.vue')
  }
};

export default tranRouter;
