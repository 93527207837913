/**
 * @description 时间戳转换为日期字符串 - 不包含时分秒
 * @param {number|string|Date} any - 时间戳或Date对象，支持10位数和13位数时间戳
 * @param {boolean} [ten=false] - 标记传入的是10位数时间戳，默认为false，即认为是13位数时间戳
 * @return {string} 返回格式化的日期字符串(YYYY-MM-DD)，若输入无效则返回'暂无'
 * @author HuangJun
 */
export const getDateNoTime = (any, ten = false) => {
  if (!any || any === 0) return '暂无';

  // 确保any为数字类型，如果是Date实例则直接使用
  any = any instanceof Date ? any.getTime() : Number(any);

  // 如果标记为10位数时间戳，则乘以1000转换为毫秒级时间戳
  if (ten) any *= 1000;

  // 创建Date对象
  const now = any instanceof Date ? any : new Date(any);

  // 获取年月日，并格式化
  const y = now.getFullYear();
  const m = String(now.getMonth() + 1).padStart(2, '0');
  const d = String(now.getDate()).padStart(2, '0');

  return `${y}-${m}-${d}`;
};
/**
 * @description 时间戳转换时间
 * @param {number} any 时间戳
 * @param {boolean} ten 是否是10位数
 * @return {string|null} 字符串日期
 * @author HuangJun
 */
export const getDate = (any, ten) => {
  if (!any || any === 0) return '暂无';
  any = Number(any);
  if (ten) any *= 1000;
  let now;
  if (any instanceof Date) now = any;
  else now = new Date(any);
  let y = now.getFullYear();
  let m = now.getMonth() + 1;
  let d = now.getDate();
  return `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d} ${now.toTimeString().substring(0, 8)}`;
};
/**
 * @description 特殊时间处理 2023-03-28T16:00:00.000+00:00
 * @param string 时间字符串
 * @return {string} 处理后时间字符串
 */
export const handleSpecialTime = string => {
  return string.substring(0, string.lastIndexOf('.')).replace('T', ' ');
};
