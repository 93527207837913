const prodRoute = {
  list: {
    path: '/podr_list',
    name: 'podrList',
    meta: {
      title: '成品采购合同 列表'
    },
    component: () => import('@/views/SalesManagement/PodrManage/PodrList.vue')
  },
  add: {
    path: '/podr_add',
    name: 'podrAdd',
    meta: {
      title: '成品采购合同 新增'
    },
    component: () => import('@/views/SalesManagement/PodrManage/PodrAdd.vue')
  },
  edit: {
    path: '/podr_edit',
    name: 'podrEdit',
    meta: {
      title: '成品采购合同 编辑'
    },
    component: () => import('@/views/SalesManagement/PodrManage/PodrEdit.vue')
  }
};

export default prodRoute;
