const dequRouter = {
  list: {
    path: '/dequ_list',
    name: 'DequList',
    component: () => import('@/views/DevelopManagement/DequManage/DequList.vue'),
    meta: {
      title: '委托设计单列表'
    }
  },
  add: {
    path: '/dequ_add',
    name: 'DequAdd',
    component: () => import('@/views/DevelopManagement/DequManage/DequAdd.vue'),
    meta: {
      title: '委托设计单 新增'
    }
  },
  edit: {
    path: '/dequ_edit',
    name: 'DequEdit',
    component: () => import('@/views/DevelopManagement/DequManage/DequEdit.vue'),
    meta: {
      title: '委托设计单 编辑'
    }
  },
  mpqStatistics: {
    path: '/mpqStatistics',
    name: 'MpqStatistics',
    component: () => import('@/views/DevelopManagement/DequManage/mpqStatisticsList.vue'),
    meta: {
      title: '稿费稿量统计'
    }
  }
};
export default dequRouter;
