const PushRouter = {
  push9010: {
    path: '/push_9010',
    name: 'push_9010',
    component: () => import('@/views/Push/Push9010.vue'),
    meta: {
      title: '取消验货提醒'
    }
  }
};
export default PushRouter;
