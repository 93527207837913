const sollRouter = {
  list: {
    path: '/soll_list',
    name: 'SollList',
    component: () => import('@/views/FinanceManagement/SollManage/SollList.vue'),
    meta: {
      title: '收款单 列表'
    }
  },
  edit: {
    path: '/soll_edit',
    name: 'SollEdit',
    component: () => import('@/views/FinanceManagement/SollManage/SollEdit.vue'),
    meta: {
      title: '收款单 编辑'
    }
  }
};
export default sollRouter;
