const fpayRouter = {
  list: {
    path: '/fpay_list',
    name: 'FpayList',
    component: () => import('@/views/FinanceManagement/FpayManage/FpayList.vue'),
    meta: {
      title: '费用付款单 列表'
    }
  },
  add: {
    path: '/fpay_add',
    name: 'FpayAdd',
    component: () => import('@/views/FinanceManagement/FpayManage/FpayAdd.vue'),
    meta: {
      title: '费用付款单 新增'
    }
  },
  edit: {
    path: '/fpay_edit',
    name: 'FpayEdit',
    component: () => import('@/views/FinanceManagement/FpayManage/FpayEdit.vue'),
    meta: {
      title: '费用付款单 编辑'
    }
  }
};
export default fpayRouter;
