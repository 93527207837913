const fcssRouter = {
  list: {
    path: '/fcss_list',
    name: 'FcssList',
    component: () => import('@/views/SettleManagement/FcssManage/FcssList.vue'),
    meta: {
      title: '面料统计 列表'
    }
  }
};
export default fcssRouter;
