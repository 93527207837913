const leaveAndClickDirective = {
  bind(el, binding, vnode) {
    let leftTargetElementTime = 0;
    el.addEventListener('mouseleave', () => {
      leftTargetElementTime = true;
    });
    document.addEventListener('click', event => {
      if (leftTargetElementTime) {
        binding.value(event);
        leftTargetElementTime = false;
      }
    });
  },
  unbind(el, binding, vnode) {
    el.removeEventListener('mouseleave', () => {});
    document.removeEventListener('click', event => {});
  }
};
export default leaveAndClickDirective;
