import topic from '../topic';
import { getNoCatch } from '@api/request';
export const stffAPI = {
  getStffs: topic.stffTopic + '/get_stffs',
  getStffById: topic.stffTopic + '/get_stff_by_id',
  getStffByDeptName: topic.stffTopic + '/getStffByDeptName',
  getAllStffsV1: topic.stffTopic + '/get_all_stffs_v1',
  getAllStffsV2: topic.stffTopic + '/get_all_stffs_v2', // 请假信息专用
  addStff: topic.stffTopic + '/add_stff',
  editStff: topic.stffTopic + '/edit_stff',
  deletStffByIds: topic.stffTopic + '/delete_stff_by_ids',
  getMyStff: topic.stffTopic + '/get_my_stff',
  getNewStffs: topic.stffTopic + '/get_new_stffs',
  getLeaveStffs: topic.stffTopic + '/get_leave_stffs',
  getPushStffs9001: topic.stffTopic + '/get_push_stffs_9001',
  updateScanById: topic.stffTopic + '/update_scan_by_id',
  exportStffListV1: topic.stffTopic + '/export_stff_list_v1',
  editStffVacation: topic.stffTopic + '/edit_stff_vacation'
};
