import axios from 'axios';
import { Message } from 'element-ui';

axios.defaults.timeout = 600000; //设置接口超时时间
axios.defaults.baseURL = process.env.VUE_APP_BASE_API; //根据环境设置基础路径/

let defaultMsg = '出现错误请稍后在试';
/**
 *get方法，对应get请求
 * @param url
 * @param params
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
export function getNoCatch(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params
      })
      .then(({ data }) => {
        if (data.code !== 0) return Message.error({ message: data.msg || defaultMsg });
        resolve(data);
      })
      .catch(errMsg => Message.error({ message: errMsg || defaultMsg }));
  });
}

/**
 *post方法，对应post请求
 * @param url
 * @param params
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
export function postNoCatch(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(({ data }) => {
        if (data.code !== 0) {
          // reject(false);
          return Message.error({ message: data.msg || defaultMsg });
        }
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
