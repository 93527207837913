const persRouter = {
  list: {
    path: '/pers_list',
    name: 'PersList',
    component: () => import('@/views/OfficeManagement/PersManage/PersList.vue'),
    meta: {
      title: '个人中心'
    }
  }
};
export default persRouter;
