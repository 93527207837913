const modrRouter = {
  list: {
    path: 'modr_list',
    name: 'modrList',
    meta: {
      title: '材料采购合同 列表'
    },
    component: () => import('@/views/SalesManagement/ModrManage/ModrList.vue')
  },

  add: {
    path: 'modr_add',
    name: 'modrAdd',
    meta: {
      title: '材料采购合同 新增'
    },
    component: () => import('@/views/SalesManagement/ModrManage/ModrAdd.vue')
  },
  edit: {
    path: 'modr_edit',
    name: 'modrEdit',
    meta: {
      title: '材料采购合同 编辑'
    },
    component: () => import('@/views/SalesManagement/ModrManage/ModrEdit.vue')
  }
};

export default modrRouter;
