import topic from '../topic';
import { getNoCatch } from '@api/request';
export const custAPI = {
  getCusts: topic.custTopic + '/get_custs',
  getCustById: topic.custTopic + '/get_cust_by_id',
  addCust: topic.custTopic + '/add_cust',
  editCust: topic.custTopic + '/edit_cust',
  deleteCustByIds: topic.custTopic + '/delete_cust_by_ids',
  getAllCustsV1: topic.custTopic + '/get_all_custs_v1',
  getAllCustsV2: topic.custTopic + '/get_all_custs_v2',
  getAllCustsV3: topic.custTopic + '/get_all_custs_v3',
  getMiddleCusts: topic.custTopic + '/get_middle_custs',
  get_all_custs_names: topic.custTopic + '/get_all_custs_names',
  getRichErpCust: params => getNoCatch(`${topic.custTopic}/get_richerp_custs`, params),
  getCustByTranForSegi: params => getNoCatch(`${topic.custTopic}/getCustByTranForSegi`, params)
};
