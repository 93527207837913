const dpvtRouter = {
  list: {
    path: '/dpvt_list',
    name: 'DpvtList',
    component: () => import('@/views/OfficeManagement/DpvtManage/DpvtList.vue'),
    meta: {
      title: '部门假期列表'
    }
  }
};
export default dpvtRouter;
