const hginRouter = {
  list: {
    path: '/hgin_list',
    name: 'HginList',
    component: () => import('@/views/FinanceManagement/HginManage/HginList.vue'),
    meta: {
      title: '收款认领清单 列表'
    }
  }
};
export default hginRouter;
