const oinvshareRouter = {
  list: {
    path: '/oinvshare_list',
    name: 'OinvShareList',
    component: () => import('@/views/SettleManagement/OinvShaerManage/OinvShareList.vue'),
    meta: {
      title: '进口商品分摊明细 列表'
    }
  },
  edit: {
    path: '/oinvshare_edit',
    name: 'OinvShareEdit',
    component: () => import('@/views/SettleManagement/OinvShaerManage/OinvShareEdit.vue'),
    meta: {
      title: '进口商品分摊明细 编辑'
    }
  }
};
export default oinvshareRouter;
