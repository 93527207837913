const statRouter = {
  list: {
    path: '/stat_list',
    name: 'StatList',
    component: () => import('@/views/FinanceManagement/StatManage/StatList.vue'),
    meta: {
      title: '结算单 列表'
    }
  },
  add: {
    path: '/stat_add',
    name: 'StatAdd',
    component: () => import('@/views/FinanceManagement/StatManage/StatAdd.vue'),
    meta: {
      title: '结算单 新增'
    }
  },
  edit: {
    path: '/stat_edit',
    name: 'StatEdit',
    component: () => import('@/views/FinanceManagement/StatManage/StatEdit.vue'),
    meta: {
      title: '结算单 编辑'
    }
  }
};
export default statRouter;
