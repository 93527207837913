const adsoRouter = {
  list: {
    path: '/adso_list',
    name: 'AdsoList',
    component: () => import('@/views/FinanceManagement/AdsoManage/AdsoList.vue'),
    meta: {
      title: 'SH预收款单 列表'
    }
  },
  add: {
    path: '/adso_add',
    name: 'AdsoAdd',
    component: () => import('@/views/FinanceManagement/AdsoManage/AdsoAdd.vue'),
    meta: {
      title: 'SH预收款单 新增'
    }
  },
  edit: {
    path: '/adso_edit',
    name: 'AdsoEdit',
    component: () => import('@/views/FinanceManagement/AdsoManage/AdsoEdit.vue'),
    meta: {
      title: 'SH预收款单 编辑'
    }
  }
};
export default adsoRouter;
