const instRouter = {
  list: {
    path: '/inst_list',
    name: 'instList',
    component: () => import('@/views/MessageManagement/InstManage/InstList.vue'),
    meta: {
      title: '机构信息 列表'
    }
  },
  add: {
    path: '/inst_add',
    name: 'instAdd',
    component: () => import('@/views/MessageManagement/InstManage/InstAdd.vue'),
    meta: {
      title: '机构信息 新增'
    }
  },
  edit: {
    path: '/inst_edit',
    name: 'instEdit',
    component: () => import('@/views/MessageManagement/InstManage/InstEdit.vue'),
    meta: {
      title: '机构信息 修改'
    }
  }
};
export default instRouter;
