const inventorysRouter = {
  list: {
    path: '/inventorys_list',
    name: 'InventorysList',
    component: () => import('@/views/InventoryManagement/InventorysManage/InventorysList.vue'),
    meta: {
      title: '订单库存 列表'
    }
  }
};
export default inventorysRouter;
