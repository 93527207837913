const prodControlRouter = {
  list: {
    path: '/prodcontrol_list',
    name: 'ProdControlList',
    component: () => import('@/views/SalesManagement/ProdControlManage/ProdControlList.vue'),
    meta: {
      title: '生产控制表 列表'
    }
  }
};
export default prodControlRouter;
