const deptRouter = {
  list: {
    path: '/dept_list',
    name: 'DeptList',
    component: () => import('@/views/SystemManagement/DeptManage/DeptList.vue'),
    meta: {
      title: '部门信息列表'
    }
  },
  add: {
    path: '/dept_add',
    name: 'DepAdd',
    component: () => import('@/views/SystemManagement/DeptManage/DeptAdd.vue'),
    meta: {
      title: '部门信息 新增'
    }
  },
  edit: {
    path: '/dept_edit',
    name: 'DeptEdit',
    component: () => import('@/views/SystemManagement/DeptManage/DeptEdit.vue'),
    meta: {
      title: '部门信息 编辑'
    }
  }
};
export default deptRouter;
