const sginRouter = {
  list: {
    path: '/sgin_list',
    name: 'SginList',
    component: () => import('@/views/FinanceManagement/SginManage/SginList.vue'),
    meta: {
      title: '收款认领清单 列表'
    }
  }
};
export default sginRouter;
