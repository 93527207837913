const clauRouter = {
  list: {
    path: '/clau_list',
    name: 'ClauList',
    component: () => import('@/views/MessageManagement/ClauManage/ClauList.vue'),
    meta: {
      title: '文本信息 列表'
    }
  },
  add: {
    path: '/clau_add',
    name: 'ClauAdd',
    component: () => import('@/views/MessageManagement/ClauManage/ClauAdd.vue'),
    meta: {
      title: '文本信息 新增'
    }
  },
  edit: {
    path: '/clau_edit',
    name: 'ClauEdit',
    component: () => import('@/views/MessageManagement/ClauManage/ClauEdit.vue'),
    meta: {
      title: '文本信息 编辑'
    }
  }
};
export default clauRouter;
