const inspectionMtrbRouter = {
  list: {
    path: '/inspmtrb_list',
    name: 'InspectionMtrbList',
    component: () => import('@/views/InspectionManagement/InspectionMtrbManage/InspectionMtrbList.vue'),
    meta: {
      title: '材料验货申请清单 列表'
    }
  }
};
export default inspectionMtrbRouter;
