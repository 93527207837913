const qutbRouter = {
  list: {
    path: '/qutb_list',
    name: 'qutbList',
    component: () => import('@/views/DevelopManagement/QutbManage/QutbList.vue'),
    meta: {
      title: '二部报价明细列表'
    }
  },
  edit: {
    path: '/qutb_edit',
    name: 'qutbEdit',
    component: () => import('@/views/DevelopManagement/QutbManage/QutbEdit.vue'),
    meta: {
      title: '二部报价明细 编辑'
    }
  }
};
export default qutbRouter;
